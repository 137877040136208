import { render, staticRenderFns } from "./program.vue?vue&type=template&id=9fb4f34e&scoped=true"
var script = {}
import style0 from "./program.vue?vue&type=style&index=0&id=9fb4f34e&prod&lang=scss&scoped=true"
import style1 from "./program.vue?vue&type=style&index=1&id=9fb4f34e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9fb4f34e",
  null
  
)

export default component.exports